/*
  New services should be added here. NO OTHER LIST OF SERVICES SHOULD EXIST

  Stores a list of:
    service:    directory name of service in the Services/ dir with - replaced with _
    route:      apigateway route for service
    localPort:  port used locally when developing
    type:       undefined if service, 'lambda' if lambda
    local:      use in the AWS environment on NLBs:  <local>.aps-services.local ie global-config.aps-services.local
    env:        env name used to find other services
 */

import { cloneDeep } from 'lodash';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const serviceRegistry: (ServicesRoutesType[number] | string)[] = require('./serviceRegistry.json');

const servicesRoutes = serviceRegistry.filter(
    (serviceRoute) => typeof serviceRoute !== 'string',
) as ServicesRoutesType;

type ServicesRoutesType = {
    service: string;
    route?: string;
    localPort?: number;
    debugPort?: number;
    type?: string;
    local?: string;
    env?: string;
    startupPriority?: number;
    runtimeEnv?: 'node' | 'java';
    liveReload?: boolean;
    allowInterServiceCall?: boolean;
}[];

export const GetServicesRoutes = (serviceName?: string): ServicesRoutesType =>
    cloneDeep(
        serviceName
            ? servicesRoutes.filter((route) => route.service === serviceName)
            : servicesRoutes,
    );

export const GetLambdaServicesRoutes = () =>
    cloneDeep(
        servicesRoutes
            .filter((svc) => svc.type === 'lambda' && svc.route)
            .map((svc) => ({
                ...svc,
                // Filtered for routes with values. Add to stop typescript thinking the route is string | undefined
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                route: svc.route!,
            })),
    );
