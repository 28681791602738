/* istanbul ignore file */ (''); // eslint-disable-line lines-around-directive

import { AppError } from './appError.types';
import { appError } from './appErrors.shared';

// Notes:
// - Consistency: Do Not include the trailing full stop in messages
// - If you include originalError, also include caller.

export {
    // Client errors
    invalidCredentials,
    invalidPermissions,
    loginRequired,
    notFound,
    alreadyExists,
    preconditionFailed,
    customBadRequest,
    // Fatal server errors
    badData,
    unrecoverableError,
    saveRecordFailed,
    updateRecordFailed,
    deleteRecordFailed,
    // db errors
    databaseQueryFailed,
    databaseSaveFailed,
    databaseUpdateFailed,
    databaseDeleteFailed,
    // Non-fatal server errors
    requestFailed,
};

//
// Generators
//

/**
 * Totally custom error where it's the caller's fault.
 * @example
 * `throw customBadRequest('Insufficient leave balance'); // >> 400 / Insufficient leave balance.`
 */
function customBadRequest(message: string, error?: unknown) {
    return appError({
        type: 'customBadRequest',
        message,
        caller: getCaller(),
        originalError: error,
    });
}

/**
 *
 */
const invalidCredentials = (message: string, error?: unknown) =>
    appError({
        type: 'invalidCredentials',
        message,
        caller: getCaller(),
        originalError: error,
    });

const invalidPermissions = (message: string, options?: Pick<AppError, 'body' | 'context'>) =>
    appError({
        type: 'invalidPermissions',
        message,
        caller: getCaller(),
        ...options,
    });

const loginRequired = (reason?: string, options?: Pick<AppError, 'body' | 'context'>) =>
    appError({
        type: 'loginRequired',
        message: reason //
            ? `Login required (${reason})`
            : 'Login required',
        caller: getCaller(),
        ...options,
    });

/**
 * The resource referenced by the caller does not exist.
 * @example
 * `throw notFound('employment', employmentId); // >> 404 / Employment 1234 does not exist.`
 */
const notFound = (resourceType: string, resourceId: string) =>
    appError({
        type: 'notFound',
        message: `${capitalise(resourceType)} ${resourceId} does not exist`,
        caller: getCaller(),
    });

const alreadyExists = (resourceType: string, resourceId: string) =>
    appError({
        type: 'alreadyExists',
        message: `${capitalise(resourceType)} ${resourceId} already exists`,
        caller: getCaller(),
    });

const saveRecordFailed = (
    errorOrReason: Error | AppError | string | unknown,
    resourceType: string,
) =>
    appError({
        type: 'saveRecordFailed',
        message: `${capitalise(resourceType)} could not be saved`,
        caller: getCaller(),
        originalError: errorOrReason,
    });

const updateRecordFailed = (
    errorOrReason: Error | AppError | string | unknown,
    resourceType: string,
) =>
    appError({
        type: 'updateRecordFailed',
        message: `${capitalise(resourceType)} could not be updated`,
        caller: getCaller(),
        originalError: errorOrReason,
    });

const deleteRecordFailed = (
    errorOrReason: Error | AppError | string | unknown,
    resourceType: string,
) =>
    appError({
        type: 'deleteRecordFailed',
        message: `${capitalise(resourceType)} could not be deleted`,
        caller: getCaller(),
        originalError: errorOrReason,
    });

/**
 * The service is broken (or a situation that should not happen, has).
 *
 * @example
 * * 500 / Validate ABA file failed
 *    * `throw unrecoverableError('validate ABA file');`
 * * 500 / Validate ABA file failed because could not find employee
 *    * `throw unrecoverableError('validate ABA file', 'could not find employee');`
 */
const unrecoverableError = (
    action: string,
    errorOrReason: Error | AppError | string | unknown,
    context?: unknown,
) =>
    appError({
        type: 'unrecoverable',
        message: capitalise(`${action} failed`),
        caller: getCaller(),
        originalError: errorOrReason,
        context,
    });

const preconditionFailed = (
    resourceType: string | undefined,
    action: string | undefined,
    reason: string,
) =>
    appError({
        type: 'preconditionFailed',
        message:
            resourceType || action ? `Cannot ${action} ${resourceType} because ${reason}` : reason,
        caller: getCaller(),
    });

/**
 * An attempt to load data from outside this service failed.
 @example
 * 502 / Upstream request for ABA file failed
  * `throw unrecoverableError('ABA file');`
 */
const requestFailed = (
    error: unknown,
    resource: string,
    options?: Pick<AppError, 'body' | 'context'>,
) =>
    appError({
        type: 'upstreamRequestFailed',
        message: `Upstream request for ${resource} failed`,
        caller: getCaller(),
        originalError: error,
        ...options,
    });

const badData = (message: string, error?: unknown) =>
    appError({
        message,
        type: 'badData',
        caller: getCaller(),
        originalError: error,
    });

const databaseFailed = (
    error: unknown,
    resourceType: string,
    action: 'query' | 'save' | 'update' | 'delete',
    context: Record<string, unknown> = {},
) =>
    appError({
        type: 'dataAccessFailed',
        message: `Database request failed for ${capitalise(resourceType)}`,
        caller: getCaller(),
        context: {
            ...context,
            action,
        },
        originalError: error,
    });

const databaseQueryFailed = (
    error: unknown,
    resourceType: string,
    context?: Record<string, unknown>,
) => databaseFailed(error, resourceType, 'query', context);

const databaseSaveFailed = (
    error: unknown,
    resourceType: string,
    context?: Record<string, unknown>,
) => databaseFailed(error, resourceType, 'save', context);

const databaseUpdateFailed = (
    error: unknown,
    resourceType: string,
    context?: Record<string, unknown>,
) => databaseFailed(error, resourceType, 'update', context);

const databaseDeleteFailed = (
    error: unknown,
    resourceType: string,
    context?: Record<string, unknown>,
) => databaseFailed(error, resourceType, 'delete', context);

saveRecordFailed.catch = (resource: string) => (error: unknown) => {
    throw saveRecordFailed(error, resource);
};

updateRecordFailed.catch = (resource: string) => (error: unknown) => {
    throw updateRecordFailed(error, resource);
};

deleteRecordFailed.catch = (resource: string) => (error: unknown) => {
    throw deleteRecordFailed(error, resource);
};

unrecoverableError.catch = (action: string, context?: unknown) => (error: unknown) => {
    throw unrecoverableError(action, error, context);
};

requestFailed.catch =
    (resource: string, options?: Pick<AppError, 'body' | 'context'>) => (error: unknown) => {
        throw requestFailed(error, resource, options);
    };

badData.catch = (error?: unknown) => (message: string) => {
    throw badData(message, error || '');
};

databaseQueryFailed.catch =
    (resource: string, context?: Record<string, unknown>) => (error: unknown) => {
        throw databaseQueryFailed(error, resource, context);
    };

databaseSaveFailed.catch =
    (resource: string, context?: Record<string, unknown>) => (error: unknown) => {
        throw databaseSaveFailed(error, resource, context);
    };

databaseUpdateFailed.catch =
    (resource: string, context?: Record<string, unknown>) => (error: unknown) => {
        throw databaseUpdateFailed(error, resource, context);
    };

databaseDeleteFailed.catch =
    (resource: string, context?: Record<string, unknown>) => (error: unknown) => {
        throw databaseDeleteFailed(error, resource, context);
    };

//
// Private methods
//

function capitalise(word: string): string {
    return word.charAt(0).toLocaleUpperCase() + word.slice(1);
}

function getCaller(): string {
    const err = new Error();
    return simplifyCallbackStack(err.stack);
}

export function simplifyCallbackStack(errorStack?: string): string {
    const stack = (errorStack || '').split('\n');

    const filteredStack = stack
        .filter(
            (line) =>
                line.match(/^\s+at /) &&
                !(
                    line.match(/.*shared-tsoa\/.*\/appError\/appErrors\.[tj]s/) ||
                    line.match(/node:internal/)
                ),
        )
        .map((line) =>
            line
                .replace(/^\s+at /, '') //
                .replace(/\/.*\/service-/, 'service-'),
        );

    return filteredStack.join('\n');
}
