import { PropsWithChildren, createContext } from 'react';
import { SessionContextState, SessionValue } from '../../lib/auth/session.types';

export const initialState: SessionContextState = {
    session: {
        loadingState: 'pending',
        isAuthenticated: false,
        isExpired: false,
        requiresResync: false,
    },
    updateSession: () => {},
};

export const SessionContext = createContext(initialState);

export const SessionProvider = ({
    children,
    value,
}: PropsWithChildren<{ value: SessionValue }>) => (
    <SessionContext.Provider
        value={{
            ...initialState,
            ...value,
        }}
    >
        {children}
    </SessionContext.Provider>
);
