import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Stack,
} from '@mui/material';
import Button from '@mui/material/Button';

export type ConfirmationModalProps = {
    title: string;
    message: React.ReactNode;
    open: boolean;
    loading?: boolean;
    cancel?: () => void;
    cancelText?: string;
    confirm: () => void;
    confirmText?: string;
};

function ConfirmationModal({
    title,
    message,
    open,
    loading,
    cancel,
    cancelText,
    confirm,
    confirmText,
}: ConfirmationModalProps): JSX.Element {
    return (
        <Dialog
            data-testid="confirmation-dialog"
            data-cy="confirmation-dialog"
            open={open}
            onClose={cancel}
            aria-labelledby="confirmation-dialog"
        >
            <DialogTitle textAlign="center">{title}</DialogTitle>
            <DialogContent color="InfoText">{message}</DialogContent>
            <DialogActions>
                <Grid container justifyContent="space-between" spacing={2}>
                    <Grid item>
                        {cancel ? (
                            <Button data-testid="btn-cancel" variant="outlined" onClick={cancel}>
                                {cancelText ?? 'Cancel'}
                            </Button>
                        ) : (
                            <></>
                        )}
                    </Grid>
                    <Grid item>
                        <Stack direction="row" spacing={2} alignItems="center">
                            {loading && <CircularProgress size="1.5rem" />}
                            <Button
                                data-testid="btn-confirm"
                                variant="contained"
                                disabled={loading}
                                onClick={confirm}
                            >
                                {confirmText ?? 'Delete'}
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmationModal;
