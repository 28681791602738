import { CSSProperties } from 'react';

/**
 * A "boot time" loading image.
 *
 * Due to the way next.js (development mode) loads and transitions between pages,
 * this image ends up being rendered twice at some points.
 * For that reason it needs to be able to overlay itself.
 */
export default function BootLoaderImage(): JSX.Element {
    const divStyle: CSSProperties = {
        display: 'flex',
        position: 'fixed',
        top: '50%',
        left: '50%',
        marginTop: '-32px',
        marginLeft: '-32px',
    };

    const loaderContainerStyle: CSSProperties = {
        boxSizing: 'border-box', // Workaround: next.js, while loading code
        display: 'flex',
        margin: 'auto',
        maxWidth: '48px',
        width: '100%',
        minWidth: 0,
        flexGrow: 0,
        flexShrink: 1,
        // Wrap the image in a box with white background (because the spinner doesn't look great on a grey background).
        border: '1px solid #0003',
        borderRadius: '5px',
        padding: '10px',
        background: 'white',
    };

    return (
        <div style={divStyle}>
            <div style={loaderContainerStyle}>
                <svg width="32" height="32" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <style
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            __html: `
                    .aur-spinner {
                        transform-origin:center;
                        animation: spinner_T6mA .75s step-end infinite;
                    }
                    
                    @keyframes spinner_T6mA{
                        8.3 % {
                            transform: rotate(30deg)
                        }
                        16.6% {
                            transform:rotate(60deg)
                        }
                        25% {
                            transform:rotate(90deg)
                        }
                        33.3% {
                            transform:rotate(120deg)
                        }
                        41.6% {
                            transform:rotate(150deg)
                        }
                        50% {
                            transform:rotate(180deg)
                        }
                        58.3% {
                            transform:rotate(210deg)
                        }
                        66.6% {
                            transform:rotate(240deg)
                        }
                        75% {
                            transform:rotate(270deg)
                        }
                        83.3% {
                            transform:rotate(300deg)
                        }
                        91.6% {
                            transform:rotate(330deg)
                        }
                        100% {
                            transform:rotate(360deg)
                        }
                    }`,
                        }}
                    />
                    <g className="aur-spinner">
                        <rect x="11" y="1" width="2" height="5" opacity=".14" />
                        <rect
                            x="11"
                            y="1"
                            width="2"
                            height="5"
                            transform="rotate(30 12 12)"
                            opacity=".29"
                        />
                        <rect
                            x="11"
                            y="1"
                            width="2"
                            height="5"
                            transform="rotate(60 12 12)"
                            opacity=".43"
                        />
                        <rect
                            x="11"
                            y="1"
                            width="2"
                            height="5"
                            transform="rotate(90 12 12)"
                            opacity=".57"
                        />
                        <rect
                            x="11"
                            y="1"
                            width="2"
                            height="5"
                            transform="rotate(120 12 12)"
                            opacity=".71"
                        />
                        <rect
                            x="11"
                            y="1"
                            width="2"
                            height="5"
                            transform="rotate(150 12 12)"
                            opacity=".86"
                        />
                        <rect x="11" y="1" width="2" height="5" transform="rotate(180 12 12)" />
                    </g>
                </svg>
            </div>
        </div>
    );
}
