/* eslint-disable no-console */
import { GetIdPLogoutURLAction } from '@aurion/service-auth/features/authentication/login.types';
import { GetUserSessionAction } from '@aurion/service-auth/features/authentication/session.types';
import axios from 'axios';
import pick from 'lodash/pick';
import { getSession } from '../../hooks/useSession';
import ApiService from '../../services/apiService';
import { TenantConfig } from './auth.types';

let tokenRefreshTimeoutDebouncer: NodeJS.Timeout;

export async function signOut(): P<string> {
    const { session, updateSession } = getSession();

    const api = ApiService.getInstance();

    const logoutResponse = await api.get<GetIdPLogoutURLAction['response']['body']>(`/auth/logout`);

    updateSession({
        ...session,
        isAuthenticated: false,
        user: undefined,
        tenant: undefined,
    });

    return logoutResponse.redirectURL;
}

export async function loadTenantConfig(): P<Pick<TenantConfig, 'api_gatewayURL' | 'env'>> {
    const keys: (keyof TenantConfig)[] = ['api_gatewayURL', 'env'];

    // The path prefix to the tenant-config-lookup service
    const bootstrapPrefixUrl = process.env.NEXT_PUBLIC_bootstrapPrefix; // eg: http://t50dev.com/api/config

    const domain = bootstrapPrefixUrl
        ? new URL(bootstrapPrefixUrl).hostname //
        : window.location.hostname;

    console.log(`Loading tenant config for ${domain}`);

    const url = bootstrapPrefixUrl
        ? `${bootstrapPrefixUrl}/${domain}` //  eg: http://t50dev.com/api/config/t50dev.com
        : `/api/config/${domain}`; //           eg: <current host>   /api/config/t50dev.com

    const res = await axios.get(url);

    const config: TenantConfig = pick(res.data, keys);
    console.table(config);

    return config;
}

export async function setTokenRefreshTimeout(api: ApiService, retryTTL: number) {
    if (tokenRefreshTimeoutDebouncer) clearTimeout(tokenRefreshTimeoutDebouncer);
    tokenRefreshTimeoutDebouncer = setTimeout(
        //
        async () => {
            const result = await api.get<GetUserSessionAction['response']['body']>('/auth/session');

            setTokenRefreshTimeout(api, result.accessTokenRetryTTL);
        },
        retryTTL * 1000,
    );
}
