import StatusCodes from 'http-status-codes';

/**
 * The list of "known" error types.
 *
 * If your error.type is not in this list (of keys) then it's not an AppError.
 *
 * Note: Do not give appErrorStatusMap a type, as this will break typescript/editors' ability to
 *       see what keys/types are available.
 *
 * You can find the codes in
 *      node/Libs/shared-tsoa/node_modules/http-status-codes/build/cjs/legacy.d.ts
 */
// prettier-ignore
const appErrorStatusMap = {
    // 3xx redirection   – further action needs to be taken in order to complete the request

    // 4xx client error  – the request contains bad syntax or cannot be fulfilled
    invalidCredentials:     StatusCodes.UNAUTHORIZED, // 401
    loginRequired:          StatusCodes.UNAUTHORIZED, // 401
    invalidPermissions:     StatusCodes.FORBIDDEN, // 403
    notFound:               StatusCodes.NOT_FOUND, // 404
    alreadyExists:          StatusCodes.CONFLICT, // 409
    customBadRequest:       StatusCodes.BAD_REQUEST, // 400
    preconditionFailed:     StatusCodes.PRECONDITION_FAILED, // 412

    // 5xx server error  – the server failed to fulfil an apparently valid request
    badData:                StatusCodes.INTERNAL_SERVER_ERROR,
    saveRecordFailed:       StatusCodes.INTERNAL_SERVER_ERROR, // 500
    updateRecordFailed:     StatusCodes.INTERNAL_SERVER_ERROR,
    deleteRecordFailed:     StatusCodes.INTERNAL_SERVER_ERROR,
    unrecoverable:          StatusCodes.INTERNAL_SERVER_ERROR,
    dataAccessFailed:       StatusCodes.BAD_GATEWAY,
    upstreamRequestFailed:  StatusCodes.BAD_GATEWAY, // 502
};

export default appErrorStatusMap;
