import { PermissionAction } from '@aurion/shared-functions/build/auth/permissions/uiConfig.types';
import { AurionDarkTheme, AurionLightTheme } from '@aurion/storybook-ui/src/themes';
import { Alert, AlertTitle, StyledEngineProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { Theme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { LicenseInfo } from '@mui/x-license-pro';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import enLocale from 'date-fns/locale/en-GB';
import { NextPage } from 'next';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { SnackbarProvider } from 'notistack';
import { ReactElement, ReactNode, useEffect } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { pdfjs } from 'react-pdf';
import ConfirmClosePanel from '../components/ConfirmClosePanel';
import '../config/bigjs';
import { useSessionValue } from '../hooks/useSession';
import {
    injectIntercomScript,
    sendIntercomBootEvent,
    sendIntercomPageUpdateEvent,
} from '../integrations/intercom';
import { signOut } from '../lib/auth/auth';
import { loadSessionState } from '../lib/auth/session';
import { SessionProvider } from '../providers/session/SessionProvider';
import ApiService from '../services/apiService';
import './App.css';
import BootLoaderImage from './BootLoaderImage';

LicenseInfo.setLicenseKey(
    'bd84a87a22d04a9f5f7107f806997a56Tz05NDU0OSxFPTE3NTI5NzYxMTQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
);

/**
 * WARNING: As we are statically hosting pdf.worker.min.js in the public/workers directory the
 * pdfjs.version must not change without updating the worker as well.
 */
pdfjs.GlobalWorkerOptions.workerSrc = `/workers/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

logBuild();

// https://nextjs.org/docs/basic-features/layouts#with-typescript
// eslint-disable-next-line @typescript-eslint/ban-types
export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
    getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
    Component: NextPageWithLayout & {
        requiredPermissions?: PermissionAction[];
    };
};

const queryClient = new QueryClient({
    defaultOptions: {
        mutations: {
            networkMode:
                process.env.NEXT_PUBLIC_api_gatewayURL === 'http://app.aurion.localhost/api'
                    ? 'always'
                    : 'online',
        },
        queries: {
            networkMode:
                process.env.NEXT_PUBLIC_api_gatewayURL === 'http://app.aurion.localhost/api'
                    ? 'always'
                    : 'online',
            refetchOnWindowFocus: false,
        },
    },
});

function ErrorFallback({ error }: FallbackProps) {
    return (
        <Alert severity="error">
            <AlertTitle>Something went wrong:</AlertTitle>
            Try refreshing the page. If that doesn't work, contact our support team. —{' '}
            {error.message}
        </Alert>
    );
}

export default function App(props: AppPropsWithLayout): JSX.Element {
    const { Component, pageProps, router } = props;

    const getLayout = Component.getLayout ?? ((page) => page);

    const { session, updateSession } = useSessionValue({
        isAuthenticated: false,
        loadingState: 'loading',
        isExpired: false,
        requiresResync: false,
    });

    const { isAuthenticated } = session;

    // TODO: Remove '&& false' - Enable dark theme
    const theme: Theme =
        useMediaQuery('(prefers-color-scheme: dark)') && false // eslint-disable-line no-constant-condition
            ? AurionDarkTheme
            : AurionLightTheme;

    useEffect(() => {
        // Note: loadSessionState() catches errors and exposes via sessionState
        loadSessionState().then(updateSession);

        Array.from(document.querySelectorAll('#loaderContainer')).forEach((el: Element) => {
            el.parentElement?.removeChild(el);
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // Conditional logic to disable ALL animations in e2e - userAgent must end in Aurion/Playwright/E2E
        if (window.navigator.userAgent.indexOf('Aurion/Playwright/E2E') !== -1) {
            theme.transitions.create = () => 'none';
        }
    }, [theme]);

    // TODO: TEMPORARY intercom stuff ================
    useEffect(injectIntercomScript, []);
    useEffect(() => {
        if (session.isAuthenticated) {
            sendIntercomBootEvent(session);
        }
    }, [session.isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (session.isAuthenticated) {
            sendIntercomPageUpdateEvent(session, router.asPath);
        }
    }, [router.asPath]); // eslint-disable-line react-hooks/exhaustive-deps
    // /end intercom temp ============================

    useEffect(() => {
        // If current page is /login/ coming from logout get rid of the logout redirect
        if (router.asPath === '/login/?from=%2Flogout%2F') {
            router.replace({ pathname: '/login' });
            return;
        }

        // If current page is /login/ or /loginFailed/ or /404/- prevent (re)routing to login
        // (eg: /login/?from=%2Flogin%2F)
        if (
            router.asPath.startsWith('/login/') ||
            router.asPath.startsWith('/loginFailed/') ||
            router.asPath.startsWith('/loginDisabled/') ||
            router.asPath.startsWith('/password/change') ||
            router.pathname.startsWith('/404') ||
            (session.error as { code?: number })?.code === 404
        )
            return;

        if ((session.error as { code?: number })?.code === 401) {
            // First time loading page, and we got an error, clean local storage, then jump to login page
            signOut()
                .then(() => {
                    // Two scenarios to handle:
                    // {tenantalias}.app.{TLD} - try to look up and redirect
                    // app.{TLD} - failover make them type tenant alias
                    const host: string[] = window.location.hostname.split('.');
                    if (host.length <= 2 || host[0] === 'app') {
                        // failover scenario
                        router.replace('/login');
                    } else {
                        // try tenant lookup
                        const tenantAliasSubdomain = host.splice(0, 1); // mutate it for use later down
                        ApiService.getInstance()
                            .get<string>(`/auth/tenants/get-by-alias/${tenantAliasSubdomain}`)
                            .then((tenantId) => {
                                router.replace(`/api/auth/login/tenant/${tenantId}`);
                            })
                            .catch(() => {
                                const l = window.location;
                                console.warn(`Did not find tenant ${tenantAliasSubdomain}`);
                                // Need to drop the "wrong" tenant alias from the hostname and reload to login
                                window.location.href = `${l.protocol}//${host.join('.')}/login/`;
                            });
                    }
                })
                .catch((error) => {
                    console.error(error); // eslint-disable-line no-console
                    router.replace('/login');
                });
        }
    }, [isAuthenticated, session.error]); // eslint-disable-line react-hooks/exhaustive-deps

    if (['pending', 'loading'].includes(session.loadingState)) {
        return <BootLoaderImage />;
    }

    const error = session.error as Error & { code: number };
    if (error && error.code !== 401) {
        return <div>{error.message}</div>;
    }

    return (
        <>
            <Head>
                {/* Note: Do not add <link rel="stylesheet"> using next/head. Use Document instead. */}

                <meta charSet="utf-8" />

                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
                />

                {/* Begin: The following settings exist in manifest.json too, so make sure you sync the changes. */}
                <meta name="theme-color" content="#25272A" />
                <meta name="description" content="T50" />
                {/* End: manifest.json */}
            </Head>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enLocale}>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                        <ErrorBoundary FallbackComponent={ErrorFallback}>
                            <CssBaseline />
                            <SnackbarProvider maxSnack={3}>
                                <SessionProvider value={{ session, updateSession }}>
                                    <QueryClientProvider client={queryClient}>
                                        <ReactQueryDevtools initialIsOpen={false} />
                                        <div id="root">
                                            <div id="App">
                                                <ConfirmClosePanel>
                                                    {getLayout(<Component {...pageProps} />)}
                                                </ConfirmClosePanel>
                                            </div>
                                        </div>
                                    </QueryClientProvider>
                                </SessionProvider>
                            </SnackbarProvider>
                        </ErrorBoundary>
                    </ThemeProvider>
                </StyledEngineProvider>
            </LocalizationProvider>
        </>
    );
}

function logBuild(): void {
    // execute code client-side only
    if (typeof window === 'undefined') {
        return;
    }

    /* eslint-disable prefer-destructuring */
    const NEXT_PUBLIC_BRANCH = process.env.NEXT_PUBLIC_BRANCH;
    const NEXT_PUBLIC_BUILD_NUMBER = process.env.NEXT_PUBLIC_BUILD_NUMBER;
    const NEXT_PUBLIC_COMMIT_ID = process.env.NEXT_PUBLIC_COMMIT_ID;
    /* eslint-enable prefer-destructuring */

    if (NEXT_PUBLIC_COMMIT_ID) {
        // eslint-disable-next-line no-console
        console.log(
            [
                `Build: ${NEXT_PUBLIC_BUILD_NUMBER}`,
                `Branch: ${NEXT_PUBLIC_BRANCH}`,
                `Commit: ${NEXT_PUBLIC_COMMIT_ID}`,
            ].join(' / '),
        );
    }
}
