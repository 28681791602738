import { Endpoint } from '@aurion/shared-functions/build/api';
import {
    CursorPagingOptions,
    OffsetPagingOptions,
} from '@aurion/shared-functions/build/pagination/types';
import { GetServicesRoutes } from '@aurion/shared-functions/build/serviceRegistry';
import { isAppError } from '@aurion/shared-tsoa/build/appError';
import axios, { AxiosError, AxiosRequestConfig, Method } from 'axios';
import { formatISO } from 'date-fns';
import { getSession } from '../hooks/useSession';
import { TenantConfig } from '../lib/auth/auth.types';
import { getDotenvVars } from '../lib/config';
import {
    BadRequestError,
    InternalServerError,
    PermissionDeniedError,
    ValidateErrorTypes,
} from '../lib/errors/index.types';

export default class ApiService {
    private static instance: ApiService;
    private auth = '';
    private tenantAlias = '';

    private baseUrl: string | undefined;

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private constructor() {}

    public setTenant(tenantAlias: string): void {
        this.tenantAlias = tenantAlias;
    }

    public static initInstance(apiGatewayUrl: string): ApiService {
        const instance = ApiService.instance || new ApiService();

        if (!apiGatewayUrl) {
            throw new Error('tenantConfig missing api_gatewayURL');
        }

        instance.setBaseUrl(apiGatewayUrl);

        ApiService.instance = instance;

        return instance;
    }

    public static getInstance(): ApiService {
        // TODO: Should we be moving configloading into this class
        if (!ApiService.instance) {
            throw new Error('ApiService need to be initialised before calling getInstance()');
        }

        return ApiService.instance;
    }

    public setBaseUrl(baseUrl: string): ApiService {
        this.baseUrl = baseUrl;
        return this;
    }

    public get<T extends EndpointTypes>(
        endpoint: Path<T>,
        options?: AxOptions,
    ): P<ResponseBody<T>> {
        return this.requestWithBody(endpoint, 'GET', undefined, options);
    }

    public post<T extends EndpointTypes, ReqBody = unknown>(
        endpoint: Path<T>,
        body?: RequestBody<T> | ReqBody,
        options?: AxOptions,
    ): P<ResponseBody<T>> {
        return this.requestWithBody(endpoint, 'POST', body, options);
    }

    public put<T extends EndpointTypes, ReqBody = unknown>(
        endpoint: Path<T>,
        body?: RequestBody<T> | ReqBody,
        options?: AxOptions,
    ): P<ResponseBody<T>> {
        return this.requestWithBody(endpoint, 'PUT', body, options);
    }

    public patch<T extends EndpointTypes, ReqBody = unknown>(
        endpoint: Path<T>,
        body?: RequestBody<T> | ReqBody,
        options?: AxOptions,
    ): P<ResponseBody<T>> {
        return this.requestWithBody(endpoint, 'PATCH', body, options);
    }

    public delete<T extends EndpointTypes>(
        endpoint: Path<T>,
        options?: AxOptions,
    ): P<ResponseBody<T>> {
        return this.requestWithBody(endpoint, 'DELETE', undefined, options);
    }

    private async requestWithBody<T>(
        endpoint: string,
        method?: Method,
        body?: unknown,
        options?: AxOptions,
    ): Promise<T> {
        if (!this.baseUrl) {
            throw new Error('ApiService not yet configured [baseUrl is null]');
        }

        const { url, isDirect } = resolveEndpointUrl(endpoint, this.baseUrl);

        const rfcTime = formatISO(new Date(), { representation: 'complete' }); // https://datatracker.ietf.org/doc/html/rfc3339#section-5.8
        const posixTz = ''; // A header with a missing part
        const tzNames = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || ''; // https://datatracker.ietf.org/doc/html/draft-ietf-dhc-timezone-01

        const headers: Record<string, any> = {
            Timezone: [rfcTime, posixTz, tzNames].join('; '), // https://datatracker.ietf.org/doc/html/draft-sharhalakis-httptz-05#section-2.1
            ...options?.headers,
            ...(this.auth
                ? {
                      credentials: 'include',
                      authorization: this.auth,
                      ...(isDirect ? { organization: this.tenantAlias } : {}),
                  }
                : {}),
        };

        const data = body;

        const config: AxiosRequestConfig = {
            method,
            url,
            data,
            headers,
            params: options?.params,
            responseType: options?.responseType,
        };

        try {
            const response = await axios(config);
            return response.data;
        } catch (error) {
            // TODO: Remove the `any` and deal with/define all the different possible variations correctly.
            const { response } = error as AxiosError<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
            if (!response) throw error;

            const { status, data: resBody } = response;

            // Immediately force reload when a status 418 is received to send the user to the outage page
            if (status === 418) {
                // eslint-disable-next-line no-restricted-globals
                location.reload();
            }

            const { session, updateSession } = getSession();

            const { appError } = resBody;

            if (isAppError(appError)) {
                if (status === 403) {
                    if (resBody.errorCode === 'REFETCH_SESSION') {
                        updateSession({ ...session, requiresResync: true });
                    }
                }

                if (status === 401 && method === 'GET') {
                    updateSession({ ...session, isAuthenticated: false });
                }

                throw {
                    type: response.statusText,
                    code: response.status,
                    message: resBody.message,
                    uuid: resBody.uuid,
                    body: resBody,
                    appError,
                    request: { method, url, headers },
                };
            }

            if (status === 400) throw new BadRequestError(resBody.message, resBody.details);
            if (status === 401 && method === 'GET') {
                // eslint-disable-next-line no-console
                console.warn(
                    'Received status 401.  Setting isAuthenticated to false (should redirect to login)',
                );
                updateSession({ ...session, isAuthenticated: false });
            }
            if (status === 403) {
                if (resBody.errorCode === 'REFRESH_SESSION') {
                    updateSession({ ...session, isExpired: true });
                }
                throw new PermissionDeniedError(
                    resBody.message,
                    response.config.url ?? '',
                    response.config.method ?? '',
                );
            }

            if (status === 412) {
                if (response.data.name === 'ZodError') {
                    throw {
                        name: 'ZodError',
                        issues: response.data.issues,
                        status: 412,
                    };
                } else {
                    throw new ValidateErrorTypes(resBody.message, resBody.details);
                }
            }
            if (status === 500) throw new InternalServerError(resBody.message, resBody.uuid);

            throw {
                type: response.statusText,
                code: response.status,
                message:
                    typeof resBody === 'object' && 'error' in resBody // not a string && ...
                        ? resBody.error
                        : resBody.message || (error as Error).message || (error as string),
                uuid: resBody.uuid,
                request: { method, url, headers },
            };
        }
    }
}

const endpointMap = GetServicesRoutes() as {
    route: string;
    service: keyof Pick<
        TenantConfig,
        | 'service_auth'
        | 'service_global_config'
        | 'service_organisation'
        | 'service_payroll'
        | 'service_super'
        | 'service_tax'
        | 'service_tenant_config'
    >;
}[];

function resolveEndpointUrl(
    endpointIn: string,
    baseUrlIn: string,
): { url: string; isDirect: boolean } {
    const endpoint = endpointIn;
    let baseUrl = baseUrlIn;
    let isDirect = false;

    const dotEnvVars = getDotenvVars();

    const mapItem = endpointMap.find(
        ({ route, service }) => dotEnvVars[service] && endpoint.startsWith(route),
    );

    if (mapItem) {
        // endpoint = endpoint.substr(mapItem.route.length - 1);
        baseUrl = (dotEnvVars as Required<TenantConfig>)[mapItem.service];
        isDirect = true;
    }

    return {
        url: `${baseUrl}${endpoint}`,
        isDirect,
    };
}

type ResBody = unknown;

export type EndpointTypes = Endpoint | OldEndpoint | ResBody;

type OldEndpoint = {
    request: { body: unknown };
    response: { body: unknown };
};

export type Path<T extends EndpointTypes> = T extends Endpoint
    ? T['path'] //
    : string;

type RequestBody<T extends EndpointTypes> = T extends Endpoint | OldEndpoint
    ? T['request']['body']
    : unknown;

export type ResponseBody<T extends EndpointTypes> = T extends Endpoint | OldEndpoint
    ? T['response']['body']
    : T;

export type AxOptions = Partial<Pick<AxiosRequestConfig, 'headers' | 'params' | 'responseType'>>;

export type WithCursorPaging<T> = T & CursorPagingOptions;
export type WithOffsetPaging<T> = T & OffsetPagingOptions;
