import { createTheme, Theme } from '@mui/material/styles';
import { colors, primary } from './colors';
import { Breakpoint, fontSize, letterSpacing } from './fontFunctions';

// Required as per: https://mui.com/x/react-data-grid/getting-started/#typescript
// Augments theme type to include MuiDataGrid.
// noinspection ES6UnusedImports
import {} from '@mui/x-data-grid-pro/themeAugmentation';

export const AurionLightTheme: Theme = createTheme({
    // See: https://material-ui.com/customization/palette/
    palette: {
        primary: {
            main: colors.primary,
            dark: colors.primaryDark,
            light: colors.primaryLight,
            contrastText: colors.text.highOnDark,
        },
        secondary: {
            main: colors.secondary,
            dark: colors.secondaryDark,
            light: colors.secondaryLight,
            contrastText: colors.text.highOnDark,
        },
        grey: {
            300: colors.greyLight,
            500: colors.grey,
            700: colors.greyDark,
        },
        // Begin: Button colours (not warning/alert background colours)
        error: {
            main: colors.danger,
            light: colors.dangerLight,
        },
        warning: {
            main: colors.warning,
            light: colors.warningLight,
            contrastText: colors.text.highOnDark,
        },
        info: {
            main: colors.info,
            light: colors.infoLight,
            contrastText: colors.text.highOnDark,
        },
        success: { main: colors.success },
        // End: Button colours

        background: {
            default: colors.background.page,
            paper: colors.white,
        },
        divider: colors.divider,
    },
    typography: {
        // The default settings can be visualised at https://mui.com/customization/default-theme/?expand-path=$.typography
        fontFamily: 'Urbanist, -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif',
        h1: {
            fontFamily: 'Poppins',
            // 96px = 6rem
            '@media (min-width:600px)': { fontSize: fontSize('h1', 6, Breakpoint.sm) },
            '@media (min-width:900px)': { fontSize: fontSize('h1', 6, Breakpoint.md) },
            '@media (min-width:1200px)': { fontSize: fontSize('h1', 6, Breakpoint.lg) },
            fontSize: fontSize('h1', 6, Breakpoint.xs),
            letterSpacing: letterSpacing(-1.5, 6), // -1.5px
        },
        h2: {
            fontFamily: 'Poppins',
            // 60px = 3.75rem
            '@media (min-width:600px)': { fontSize: fontSize('h2', 3.75, Breakpoint.sm) },
            '@media (min-width:900px)': { fontSize: fontSize('h2', 3.75, Breakpoint.md) },
            '@media (min-width:1200px)': { fontSize: fontSize('h2', 3.75, Breakpoint.lg) },
            fontSize: fontSize('h2', 3.75, Breakpoint.xs),
            letterSpacing: letterSpacing(-0.5, 3.75), // -0.5px;
        },
        h3: {
            fontFamily: 'Poppins',
            // 48px = 3rem
            '@media (min-width:600px)': { fontSize: fontSize('h3', 3, Breakpoint.sm) },
            '@media (min-width:900px)': { fontSize: fontSize('h3', 3, Breakpoint.md) },
            '@media (min-width:1200px)': { fontSize: fontSize('h3', 3, Breakpoint.lg) },
            fontSize: fontSize('h3', 3, Breakpoint.xs),
            letterSpacing: 'initial',
        },
        h4: {
            fontFamily: 'Poppins',
            // 34px = 2.125rem
            '@media (min-width:600px)': { fontSize: fontSize('h4', 2.125, Breakpoint.sm) },
            '@media (min-width:900px)': { fontSize: fontSize('h4', 2.125, Breakpoint.md) },
            '@media (min-width:1200px)': { fontSize: fontSize('h4', 2.125, Breakpoint.lg) },
            fontSize: fontSize('h4', 2.125, Breakpoint.xs),
            letterSpacing: 'initial',
        },
        h5: {
            fontFamily: 'Poppins',
            // 24px = 1.5rem
            '@media (min-width:600px)': { fontSize: fontSize('h5', 1.5, Breakpoint.sm) },
            '@media (min-width:900px)': { fontSize: fontSize('h5', 1.5, Breakpoint.md) },
            '@media (min-width:1200px)': { fontSize: fontSize('h5', 1.5, Breakpoint.lg) },
            fontSize: fontSize('h5', 1.5, Breakpoint.xs),
            letterSpacing: letterSpacing(0.18, 1.5), // 0.18px;
        },
        h6: {
            fontFamily: 'Poppins',
            // 20px = 1.25rem
            '@media (min-width:600px)': { fontSize: fontSize('h6', 1.25, Breakpoint.sm) },
            '@media (min-width:900px)': { fontSize: fontSize('h6', 1.25, Breakpoint.md) },
            '@media (min-width:1200px)': { fontSize: fontSize('h6', 1.25, Breakpoint.lg) },
            fontSize: fontSize('h6', 1.25, Breakpoint.xs),
            letterSpacing: letterSpacing(0.15, 1.25), // 0.15px;
        },
        // Sizes should be specified in REM's (not pixels).  The base font size is 16px.
        subtitle1: {
            fontSize: '1rem',
            fontWeight: 'bold',
            letterSpacing: letterSpacing(0.5, 1), // 0.5px (relative to 1rem)
            lineHeight: 1.5, // 24px (relative to 1rem)
        },
        subtitle2: {
            fontSize: `${14 / 16}rem`, // 14px
            fontWeight: 600,
            letterSpacing: letterSpacing(0.5, 14 / 16), // .5px (relative to 14px)
        },
        body1: {
            fontSize: '1rem',
            fontWeight: 'normal',
            letterSpacing: letterSpacing(0.5, 1), // 0.5px (relative to 1rem)
        },
        body2: {
            fontSize: `${14 / 16}rem`, // 14px
            fontWeight: 'normal',
            lineHeight: 1.42857, // 20px (relative to 14px)
            letterSpacing: letterSpacing(0.5, 14 / 16), // 0.5px (relative to 14px)
        },
        button: {
            fontSize: `${14 / 16}rem`, // 14px
            fontWeight: 600,
            lineHeight: '1rem', // 16px
            letterSpacing: letterSpacing(1.25, 14 / 16), // 1.25px
            textTransform: 'none',
        },
        caption: {
            fontSize: `${12 / 16}rem`, // 12px
            fontWeight: 'normal',
            lineHeight: '1rem',
            letterSpacing: letterSpacing(0.5, 12 / 16), // 0.4px
        },
        overline: {
            fontWeight: 600,
            fontSize: `${10 / 16}rem`, // 10px
            lineHeight: '1rem',
            letterSpacing: letterSpacing(1.5, 10 / 16), // 1.5px
        },
    },
});

AurionLightTheme.components = {
    MuiAppBar: {
        styleOverrides: {
            root: {
                backgroundColor: colors.background.mainNavigationBar,
                color: '#ffffff80', // white 50% (from Figma 01 Admin > Navigation)
            },
        },
    },
    MuiAlert: {
        styleOverrides: {
            root: ({ theme, ownerState: { severity, variant } }) => ({
                // Alert 'filled' variant does not use contrastText correctly.
                // https://github.com/mui/material-ui/issues/33512
                ...(variant === 'filled' &&
                    severity && {
                        color: theme.palette[severity].contrastText,
                    }),
            }),
        },
    },
    MuiListItem: {
        styleOverrides: {
            button: {
                '&:hover': {
                    backgroundColor: as<typeof primary>(AurionLightTheme.palette.primary)['50'], // Created for  PageContainerUI navigation items.
                },
            },
        },
    },
    MuiAvatar: {
        styleOverrides: {
            root: ({ theme }) => ({
                backgroundColor: theme.palette.primary.main,
                width: '2.25rem',
                height: '2.25rem',
            }),
        },
    },
    MuiButton: {
        defaultProps: {
            variant: 'outlined',
            size: 'medium',
            color: 'primary',
        },
        styleOverrides: {
            root: ({ theme, ownerState: { color, variant = 'outlined', size = 'medium' } }) => {
                type CSSProperties = {
                    [key: string]: string | number | CSSProperties;
                };
                const buttonHeight = {
                    small: 24,
                    medium: 32,
                    large: 40,
                };

                // css config for each variant type
                const variantMapping: Record<string, CSSProperties> = {
                    outlined: {
                        border: '1px solid',
                        borderColor:
                            color && color !== 'inherit' ? theme.palette[color].main : 'default',
                        color: color && color !== 'inherit' ? theme.palette[color].main : 'default',
                    },
                    text: {
                        border: 'none',
                        color: color && color !== 'inherit' ? theme.palette[color].main : 'default',
                    },
                    contained: {
                        color: 'white',
                        backgroundColor:
                            color && color !== 'inherit' ? theme.palette[color].main : 'default',
                    },
                };

                const cssConfig = {
                    // Default css for all buttons
                    lineHeight: '16px',
                    minHeight: `${buttonHeight[size]}px`,
                    borderRadius: `${buttonHeight[size] / 2}px`,
                    // variant specific styling
                    ...variantMapping[variant],
                    '&:disabled': {
                        opacity: 0.38,
                        borderColor: 'inherit',
                        color:
                            color && color !== 'inherit'
                                ? theme.palette[color].contrastText
                                : 'default',
                        backgroundColor:
                            color && color !== 'inherit' ? theme.palette[color].main : 'default',
                    },
                };

                return {
                    ...cssConfig,
                };
            },
        },
    },
    MuiInput: {
        styleOverrides: {
            underline: {
                '&:after': {
                    borderBottomColor: AurionLightTheme.palette.primary.dark,
                },
            },
        },
    },
    MuiFilledInput: {
        styleOverrides: {
            underline: {
                '&:after': {
                    borderBottomColor: AurionLightTheme.palette.primary.dark,
                },
            },
        },
    },
    MuiOutlinedInput: {
        styleOverrides: {
            root: {
                '&:focused :notchedOutline': {
                    borderColor: AurionLightTheme.palette.primary.dark,
                },
            },
            notchedOutline: {
                paddingLeft: '9px', // legend label spacing (default: 8px)
                paddingRight: '9px', // legend label spacing (default: 8px)
                '& legend > span': {
                    paddingLeft: '4px', // legend label spacing (default: 5px)
                    paddingRight: '4px', // legend label spacing (default: 5px)
                },
            },
        },
    },
    MuiFormLabel: {
        styleOverrides: {
            root: {
                '&:focused': {
                    color: AurionLightTheme.palette.primary.dark,
                },
            },
        },
    },
    MuiAccordion: {
        styleOverrides: {
            root: {
                borderRadius: '1rem !important',
            },
        },
    },
    MuiDataGrid: {
        styleOverrides: {
            root: {
                height: '100%',
                backgroundColor: 'transparent',
                border: 'none',
                borderRadius: '16px',
                '.MuiDataGrid-toolbarContainer': {
                    color: AurionLightTheme.palette.common.white,
                    backgroundColor: AurionLightTheme.palette.primary.main,
                    padding: '10px 16px 0 16px',
                    borderRadius: '16px 16px 0 0',

                    '.MuiSwitch-track': {
                        opacity: '0.33',
                    },

                    '.MuiButtonBase-root': {
                        color: 'inherit',
                        '&.MuiButton-root: hover': {
                            backgroundColor: AurionLightTheme.palette.primary.light,
                        },
                        '&:disabled': {
                            opacity: '0.38',
                        },

                        '&.MuiSwitch-switchBase': {
                            color: colors.greyLight,

                            '&.Mui-checked': {
                                color: colors.white,
                                '+.MuiSwitch-track': {
                                    backgroundColor: colors.white,
                                    opacity: '0.33',
                                },
                            },
                        },
                    },
                    '.MuiTabs-indicator': {
                        backgroundColor: AurionLightTheme.palette.common.white,
                    },
                    '.MuiInputBase-root': {
                        backgroundColor: AurionLightTheme.palette.common.white,
                        borderRadius: '8px',
                        height: '34px',
                        '.MuiSvgIcon-root': {
                            color: AurionLightTheme.palette.primary.main,
                        },
                        fieldset: {
                            top: '0',
                            '& > legend': {
                                display: 'none',
                            },
                        },
                    },
                },

                '.MuiDataGrid-columnHeaders': {
                    backgroundColor: AurionLightTheme.palette.grey['300'],
                    color: AurionLightTheme.palette.common.white,
                    borderRadius: '0',
                    ...AurionLightTheme.typography.body1,

                    '.MuiButtonBase-root': {
                        color: 'inherit',
                    },
                    '.Mui-disabled': {
                        color: AurionLightTheme.palette.grey.A400,
                    },
                },

                '.MuiDataGrid-row': {
                    color: AurionLightTheme.palette.grey['700'],
                    backgroundColor: AurionLightTheme.palette.background.paper,
                    ...AurionLightTheme.typography.body1,

                    '.MuiButtonBase-root': {
                        color: AurionLightTheme.palette.primary.main,
                    },
                    '.MuiButton-containedPrimary': {
                        color: AurionLightTheme.palette.common.white,
                    },
                    '&.Mui-disabled, .Mui-disabled': {
                        color: AurionLightTheme.palette.grey.A400,
                    },
                    '&.Striped': {
                        backgroundColor: AurionLightTheme.palette.grey['200'],
                    },
                },

                // Adds '...' to overflow of tree data grouping cells when the content is too long
                '.MuiDataGrid-cell': {
                    '.MuiDataGrid-treeDataGroupingCell': {
                        overflow: 'hidden',

                        span: {
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        },
                    },
                },

                // INFO: This is for hiding master detail toggle button when we don't want to display it
                '.MuiDataGrid-detailPanelToggleCell.Mui-disabled': { display: 'none' },

                '.MuiDataGrid-virtualScroller': {
                    backgroundColor: AurionLightTheme.palette.background.paper,
                },

                '.MuiDataGrid-footerContainer': {
                    borderColor: AurionLightTheme.palette.divider,
                    backgroundColor: AurionLightTheme.palette.background.paper,
                    borderRadius: '0 0 8px 8px',
                },

                '.hideRightSeparator': {
                    '& > .MuiDataGrid-columnSeparator': {
                        visibility: 'hidden',
                    },
                },
            },
        },
    },
    MuiChip: {
        styleOverrides: {
            root: {
                fontWeight: 600,
                paddingRight: '4px',
                paddingLeft: '4px',
                '&.MuiChip-colorDefault': {
                    color: 'white',
                },
                '&.MuiChip-filledDefault': {
                    backgroundColor: colors.greyLight,
                },
                '.MuiChip-iconColorDefault': {
                    color: 'white',
                },
            },
        },
    },
};

export default AurionLightTheme;

function as<T>(x: unknown): T {
    return x as T;
}
