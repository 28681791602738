import { createTheme } from '@mui/material/styles';
import { AurionLightTheme } from './AurionLight';
import { colors } from './colors';

export const AurionDarkTheme: typeof AurionLightTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: { main: colors.primaryLight },
        secondary: { main: colors.secondaryLight },
        grey: {
            300: colors.greyDark,
            500: colors.grey,
            700: colors.greyLight,
        },
        error: { main: colors.dangerLight },
        warning: { main: colors.warningLight },
        info: { main: colors.infoLight },
        success: { main: colors.successLight },
    },
    typography: {
        button: {
            textTransform: 'none',
        },
    },
});

AurionDarkTheme.components = {
    MuiAppBar: {
        styleOverrides: {
            root: {
                backgroundColor: '#3A3A3A',
                color: '#ffffff80', // white 50% (from Figma 01 Admin > Navigation)
            },
        },
    },
};

export default AurionDarkTheme;
