/*
    TODO:
    This is VERY temporary until the business settles
    on intercom or not, then this gets done properly
 */

import { Portal } from '@aurion/shared-functions/build/auth/permissions/uiConfig.types';
import { Session } from '../lib/auth/session.types';

const IntercomAppId = 'o205jejn';

function shouldNotRunIntercom() {
    const isRunningInE2E = window.navigator.userAgent.indexOf('Aurion/Playwright/E2E') !== -1;
    return !!(window.location.hostname.indexOf('localhost') !== -1 || isRunningInE2E);
}

function getEmailDomain(email: string | undefined): string {
    return email ? email.split('@')[1] : 'unknown';
}

function getHighestPortal(portals: Portal[]) {
    switch (true) {
        case portals.includes('global'):
            return 'global-admin';
        case portals.includes('admin'):
            return 'tenant-admin';
        case portals.includes('manager'):
            return 'manager';
        default:
            return 'employee';
    }
}

/**
 * Injects the Intercom Messenger widget into the app.
 */
export function injectIntercomScript(): void {
    if (shouldNotRunIntercom()) {
        return;
    }
    // @ts-ignore
    window.intercomSettings = {
        app_id: IntercomAppId,
        api_base: 'https://api-iam.au.intercom.io',
        hide_default_launcher: true,
    };
    const script = document.createElement('script');
    script.defer = true;
    script.src = `https://widget.intercom.io/widget/${IntercomAppId}`;
    document.body.appendChild(script);
}

/**
 * Build settings to send to Intercom.
 *
 * Useful for actions that require user attributes to be provided.
 */
function buildIntercomSettings(session: Session, options: Record<string, unknown> = {}) {
    if (!session.user) return undefined;

    return {
        email: session.user.email || 'unknown_user@aurion.com',
        email_domain: getEmailDomain(session.user.email),
        user_id: session.user.username,
        first_name: session.user.givenName,
        last_name: session.user.familyName,
        user_level: getHighestPortal(session.user.portals),
        env_name: session.env || 'unknown_env',
        tenant_alias: session.tenant?.tenantAlias,
        base_url: 't50app',
        ...(options || {}),
    };
}

/**
 * Notify Intercom that the user has logged in and Intercom has been bootstrapped.
 *
 * https://developers.intercom.com/installing-intercom/web/methods#intercomboot-intercomsettings
 */
export function sendIntercomBootEvent(session: Session): void {
    const intercomSettings = buildIntercomSettings(session);
    sendIntercomAction('boot', intercomSettings);
}

/**
 * Notify Intercom that the user is viewing a new page.
 *
 * https://developers.intercom.com/installing-intercom/web/methods#intercomupdate
 *
 * @param page The relative path of the new page.
 */
export function sendIntercomPageUpdateEvent(session: Session, page: string): void {
    const intercomSettings = buildIntercomSettings(session, { page });
    sendIntercomAction(session, intercomSettings);
}

/**
 * Send Intercom an action.
 *
 * Available actions can be found here: https://developers.intercom.com/installing-intercom/web/methods
 */
export function sendIntercomAction(...intercomActionArgs: unknown[]) {
    // @ts-ignore
    if (window.Intercom) {
        // @ts-ignore
        window.Intercom(...intercomActionArgs);
    }
}
