export class ValidateErrorTypes extends Error {
    readonly status: number;
    constructor(message: string, public details: FieldErrors) {
        super(message);
        this.status = 412;
        this.name = 'validate error';
    }
}

export interface FieldErrors {
    [name: string]: { message: string; value?: any };
}
