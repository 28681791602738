import { useContext, useState } from 'react';
import {
    Session,
    SessionValue,
    SetTestSessionValue,
    UpdateSession,
} from '../lib/auth/session.types';
import { SessionContext, initialState } from '../providers/session/SessionProvider';

let { session } = initialState;
let updateSession: UpdateSession;

export function useSession() {
    const context = useContext(SessionContext);
    if (context === undefined) {
        throw new Error('useSession must be used within a SessionProvider');
    }

    return context;
}

/**
 * Only to be called by top level component (App.tsx)
 */
export function useSessionValue(initialSession: Session): SessionValue {
    [session, updateSession] = useState<Session>(initialSession);
    return { session, updateSession };
}

export function getSession(): Pick<SessionValue, 'session' | 'updateSession'> {
    return { session, updateSession };
}

//
// Private methods ---------------------------------------------------------------------------------
//

/* istanbul ignore next */
export function setTestSession(value: SetTestSessionValue): void {
    if (!process.env.JEST_WORKER_ID) {
        throw new Error('setTestSession is for Jest/testing purposes only!');
    }
    [session, updateSession] = value;
}
