import { GetUserSessionAction } from '@aurion/service-auth/features/authentication/session.types';
import { GetTenantSettingsAction } from '@aurion/service-tenant-config/tenantSettings/tenantSettings.types';
import ApiService from '../../services/apiService';
import { loadTenantConfig, setTokenRefreshTimeout } from './auth';
import { Session } from './session.types';

export async function loadSessionState(): P<Session> {
    try {
        const tenantConfig = await loadTenantConfig();

        const api = ApiService.initInstance(tenantConfig.api_gatewayURL);

        const {
            tenantId,
            tenantAlias,
            userId,
            tenantTimeZone,
            portals,
            profiles,
            username,
            familyName,
            givenName,
            email,
            employeeId,
            isMfaLogin,
            permissions,
            accessTokenRetryTTL: retryTTL,
        } = await api.get<GetUserSessionAction['response']['body']>('/auth/session');

        setTokenRefreshTimeout(api, retryTTL);

        // Get settings for tenant
        const tenantSettings = await api.get<GetTenantSettingsAction>(
            `/tenantconfig/tenant/${tenantId}/settings`,
        );

        const newSession: Session = {
            loadingState: 'loaded',
            isAuthenticated: true,
            isExpired: false,
            requiresResync: false,
        };

        return {
            ...newSession,
            tenant: {
                tenantId,
                tenantAlias,
                tenantName: tenantAlias,
                tenantLogoURL: `/publicimage/${tenantId}/${tenantSettings.tenantLogoFileName}`,
                tenantTimeZone,
            },
            env: tenantConfig.env,
            user: {
                username,
                familyName,
                givenName,
                userId,
                email,
                employeeId,
                isMfaLogin,
                portals,
                profiles,
                permissions,
            },
        };
    } catch (error) {
        return {
            loadingState: 'error',
            isAuthenticated: false,
            isExpired: false,
            requiresResync: false,
            error: error as Error,
        };
    }
}
