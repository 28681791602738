export enum Breakpoint {
    xs,
    sm,
    md,
    lg,
}

const factorsTable = {
    h1: [3.5, 4.7129, 5.3556, 5.9983],
    h2: [2.375, 3.125, 3.3333, 3.75],
    h3: [2, 2.5707, 2.7849, 2.9991],
    h4: [1.5625, 1.8219, 2.0243, 2.0243],
    h5: [1.25, 1.3118, 1.4993, 1.4993],
    h6: [1.125, 1.25, 1.25, 1.25],
};

export function fontSize(
    variant: keyof typeof factorsTable,
    targetInRems: number,
    breakpoint: Breakpoint = Breakpoint.xs,
): string {
    const factor = factorsTable[variant][breakpoint];
    const factorBase = factorsTable[variant][Breakpoint.lg];

    return factor === factorBase
        ? `${targetInRems}rem`
        : `${targetInRems * (factor / factorBase)}rem`;
}

export function letterSpacing(targetPixels: number, relativeFontSizeInEms: number): string {
    return `${targetPixels / 16 / relativeFontSizeInEms}em`;
}
