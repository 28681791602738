import { TenantConfig } from './auth/auth.types';

/**
 * Return all the NEXT_PUBLIC_* environment variables (in .env*) but with the prefix removed.
 */
export function getDotenvVars(): Pick<
    TenantConfig,
    | 'service_auth'
    | 'service_global_config'
    | 'service_organisation'
    | 'service_payroll'
    | 'service_super'
    | 'service_tax'
    | 'service_tenant_config'
> {
    const tenantOverrides = {
        service_auth: process.env.NEXT_PUBLIC_service_auth,
        service_global_config: process.env.NEXT_PUBLIC_service_global_config,
        service_organisation: process.env.NEXT_PUBLIC_service_organisation,
        service_payroll: process.env.NEXT_PUBLIC_service_payroll,
        service_super: process.env.NEXT_PUBLIC_service_super,
        service_tax: process.env.NEXT_PUBLIC_service_tax,
        service_tenant_config: process.env.NEXT_PUBLIC_service_tenant_config,
    };

    const overridesRec = tenantOverrides as Record<string, unknown>;

    // Remove undefined entries
    Object.keys(overridesRec)
        .filter((k) => overridesRec[k] === undefined)
        .forEach((k) => delete overridesRec[k]);

    return tenantOverrides;
}
