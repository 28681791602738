/* istanbul ignore file */ (''); // eslint-disable-line lines-around-directive

import type statusMap from './appError.statusMap';
import { AppError, StatusMap } from './appError.types';

export function isAppError(error: unknown): error is AppError {
    return (
        typeof error === 'object' &&
        !!error &&
        'type' in error && //
        'message' in error &&
        !!(error as AppError).type &&
        !!(error as AppError).message &&
        !!('____isAppError' in error)
    );
}

export function appError<Map extends StatusMap = StrictStatusMap>(
    appErrorProps: AppError<Map>,
): AppError<Map> {
    return Object.assign(appErrorProps, { ____isAppError: true });
}

type StrictStatusMap = typeof statusMap;
