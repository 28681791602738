import { Color } from '@mui/material';
import { orange as muiOrange, purple as muiPurple } from '@mui/material/colors';

/*
 * References:
 *
 * MUI > Colour
 *  - https://mui.com/customization/color/#color-palette
 *
 * What exactly are Google's '500' color swatches?
 *  - https://graphicdesign.stackexchange.com/questions/43021/what-exactly-are-googles-500-color-swatches
 *
 * Material.io Color tool
 *  - https://material.io/resources/color/#!/?view.left=1&view.right=1&primary.color=a559ea&secondary.color=FF8026
 *  - Note the accessibility considerations
 */

/**
 * Colours, as defined by UI Designer.
 * The structure is arbitrary, but somewhat reflective of the Figma design.
 */
export const colors = {
    // Figma `01 Admin > Aurion T50 Design System > Color`
    primary: '#a559ea',
    primaryLight: '#DA89FF',
    primaryDark: '#7129B7',
    primaryExtraLight: '#EDDEFB',
    secondary: '#FF8026',
    secondaryLight: '#FFB157',
    secondaryDark: '#C55100',
    grey: '#2D2D2D', // 500
    greyLight: '#565656', // 300
    greyDark: '#020202', // 700
    white: '#FFFFFF',

    // Background Colours
    background: {
        mainNavigationBar: '#3A3A3A', // '#2D2D2D',
        appNavigation: '#FFFFFF',
        page: '#F4F4F4',
    },
    danger: '#D32F2F',
    dangerLight: '#FFCFCF',
    success: '#0A8A11',
    successLight: '#C7F4C9',
    warning: '#FF8026',
    warningLight: '#FFDAAE',
    info: '#04A7ED',
    infoLight: '#B5E4F9',

    // Font/Text Colours
    text: {
        // text on light background
        high: '#000000F2', // black 95% - High Emphasis
        medium: '#00000099', // black 60% - Medium Emphasis
        disabled: '#00000066', // black 40% - Disabled
        error: '#B00020', // Error Text
        // text on dark background
        highOnDark: '#FFFFFFFA', // white 98%
        mediumOnDark: '#FFFFFFE0', // white 88%
        disabledOnDark: '#FFFFFFC4', // white 77%
    },
    error: '#B00020',

    // Text box/Dropdown List/Separator Lines
    divider: '#D4D4D8E6', // #D4D4D8 90%
};

/**
 * A palette of shades based on the the primary colour.
 * PALETTE colours - https://material.io/inline-tools/color/
 */
export const primary: Color = {
    900: '#3300b3',
    800: '#4d00c1',
    700: '#5c00c8',
    600: '#6c00d1',
    500: '#7600d6',
    400: '#8e28e5',
    300: '#a459ea', // << Primary colour.  Note that because the primary is too low, the tool drops the primary from 500 to 300.
    200: '#be8def',
    100: '#d8bbf5',
    50: '#f1e3fb',
    // Accent colors
    A700: muiPurple.A700, // used by primary darkShade
    A400: muiPurple.A400, // used by primary main
    A200: muiPurple.A200, // used by primary lightShade
    A100: muiPurple.A100,
};

/**
 * A palette of shades based on the the secondary colour.
 * PALETTE colours - https://material.io/inline-tools/color/
 */
export const secondary: Color = {
    900: '#ff8026',
    800: '#ffaa30',
    700: '#ffc236',
    600: '#ffda3c',
    500: '#ffe436',
    400: '#ffea56',
    300: '#ffef76',
    200: '#fff49d',
    100: '#fff9c4',
    50: '#fffde7',
    A700: muiOrange.A700,
    A400: muiOrange.A400,
    A200: muiOrange.A200,
    A100: muiOrange.A100,
};
