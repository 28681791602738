import React, { createContext, PropsWithChildren, useContext, useReducer } from 'react';
import ConfirmationDialog from './other/dialog/ConfirmationDialog';

type ConfirmClosePanelProp = {
    children: React.ReactNode;
};

function createReducerContext<S, A>(defaultValue: S, reducer: (s: S, a: A) => S) {
    type Dispatch = (a: A) => void;

    const ctx = createContext<
        | {
              state: typeof defaultValue;
              dispatch: Dispatch;
          }
        | undefined
    >(undefined);

    function Provider(props: PropsWithChildren<Record<string, unknown>>) {
        const [state, dispatch] = useReducer(reducer, defaultValue);
        return <ctx.Provider value={{ state, dispatch }} {...props} />;
    }

    return [ctx, Provider] as const;
}

export default function ConfirmClosePanel({ children }: ConfirmClosePanelProp): JSX.Element {
    return (
        <>
            <ClosingProvider>
                {children}
                <ConfirmWrapper />
            </ClosingProvider>
        </>
    );
}

export function ConfirmWrapper() {
    const context = useClosePanelContext();
    const onCancel = () => {
        context.dispatch({ type: 'isClosePanel', isClosing: false, callback: () => {} });
    };
    return (
        <ConfirmationDialog
            title="Are you sure you want to leave?"
            message="Any changes will not be saved."
            open={context.state.isClosing}
            cancel={() => onCancel()}
            confirm={() => context.state.callback()}
            confirmText="Discard changes"
        />
    );
}
export function confirmClosePanelReducer(
    state: ClosingPanelState,
    action: ConfirmClosePanelAction,
): ClosingPanelState {
    switch (action.type) {
        case 'isClosePanel':
            return { ...state, isClosing: action.isClosing, callback: action.callback };
        default:
            return state;
    }
}

export const [ctx, ClosingProvider] = createReducerContext(
    { isClosing: false, callback: () => {} },
    confirmClosePanelReducer,
);

export const ClosingContext = ctx;

export function useClosePanelContext() {
    const context = useContext(ClosingContext);
    if (context === undefined) {
        throw new Error('useClosePanelContext must be used within a ClosingProvider');
    }
    return context;
}

export function useClosePanelDispatch() {
    const context = useContext(ClosingContext);
    if (context === undefined) {
        throw new Error('useClosePanelDispatch must be used within a ClosingProvider');
    }
    return context.dispatch;
}

export type ClosingPanelState = {
    isClosing: boolean;
    callback: () => unknown;
};

export type ConfirmClosePanelAction = {
    type: 'isClosePanel';
    isClosing: boolean;
    callback: () => unknown;
};
